import React from 'react'

import Layout from 'src/layouts/BaseLayout'

import VoceFoiConvocado from './sections/voce-foi-convocado/_index'
import SoccerBackground from './sections/soccer-background/_index'
import InterNYCFC from './sections/inter-nycfc/_index'
import FormMobile from './sections/form-mobile/_index'

import pageContext from './pageContext.json'
import { Wrapper } from './style'
import useWidth from 'src/hooks/window/useWidth'

const NYCFC = () => {
  const WIDTH_MD = 768
  const windowWidth = useWidth(300)
  const lang = 'pt'
  const isMobile = windowWidth < WIDTH_MD

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <VoceFoiConvocado lang={lang} />
        {isMobile ? <FormMobile lang={lang} /> : null}
        <SoccerBackground lang={lang} />
        <InterNYCFC lang={lang} />
      </Layout>
    </Wrapper>
  )
}

export default NYCFC
